/* MyNabar CSS */

.my-navbar {
  height: 70px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, top 0.5s ease,
    transform 0.5s ease;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.1);
}

.my-navbar.non-sticky {
  position: fixed;
  top: 0;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.1);
}

.my-navbar.sticky {
  position: fixed;
  top: 0;
  background-color: #00414e;
  color: aliceblue;
}

.my-navbar.top {
  background: none !important; /* Ensure no background */
  background-color: transparent !important; /* Explicitly set background color to transparent */
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.1);
}

.my-navbar.visible {
  transform: translateY(0);
}

.my-navbar.hidden {
  transform: translateY(-100%);
}

.my-navbar.scrolled {
  /* background-color: rgba(0, 65, 78, 0.9); */
  background-color: #FFF5E4;
  color: aliceblue;
  backdrop-filter: blur(10px);
}

.my-navlink {
  font-size: 16px;
  --bs-navbar-nav-link-padding-x: 1.5rem;
  font-weight: 600;
  margin-bottom: 4px;
}

/* for nav item that's not active */
.scrolled {
  color: #909090 !important;
}

/* for active nav item */
.linkteal-active {
  color: #FFF5E4 !important;
}

/* for scrolled active nav item */
.navlink-active {
  color: #00414e !important;
}

/* for scrolled nav item that's not active, hovered */
.my-navlink:hover {
  color: #00414e;
}

/* for all nav item at top, hovered */
.my-navlink.nav-top:hover {
  color: #FFF5E4 !important;
}

/* for all nav item scrolled, hovered */
.my-navlink.scrolled:hover {
  color: #00414e !important;
}

.linkteal-active:hover{
  color: #FFF5E4 !important;
}

.tealnav-form,
.tealnav-form:hover{
  color: #00414e !important;
}

.navbar-placeholder {
  height: 85px; /* Same height as the navbar */
}

.navbar-placeholder.hidden {
  display: none;
}

.custom-career {
  color: #00414e;
  font-size: 20px;
  font-weight: 600;
  padding-top: 8px;
  margin-bottom: 9px;
}

.custom-career.scrolled {
  color: #00414e !important;
}

@media (max-width: 992px) {
  .navbar-brand {
    padding-top: 0px;
  }
}

@media (min-width: 1200px) {
  .navbar-container {
    padding: 0rem auto;
  }
}

/* Navbar styles */
.navbar-logo {
  height: 40px;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.secondary-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 65, 78, 0.9);
  backdrop-filter: blur(10px);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  box-shadow: 0 3px 3px rgb(0, 0, 0);
}

.secondary-navbar .custom-career {
  color: white;
}

.secondary-navbar .bi-x {
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.secondary-nav-links {
  width: 100%;
}

.secondary-nav-links .nav-link {
  font-size: 1.6rem;
  font-weight: 600;
  color: white;
  margin: 10px 0;
}

.toggle-icon {
  font-size: 2rem;
  color: #00414e;
}

/* Job Item CSS */

/* General container for job items */
.bg-red{
  background-color: #ff0000 !important;
}

.job-item-container {
  padding: 0;
  margin: 0;
}

/* Accordion Header */
.job-header {
  cursor: pointer;
  border-bottom: 1.5px solid #cccccc !important;
}

.job-header .title {
  font-weight: bold;
  font-size: 1.25rem; /* Matches the heading size in your image */
  margin-top: 0.65rem;
  color: #000;
}

.job-header i.bi-chevron-down {
  font-size: 1.25rem;
  color: #6c757d; /* Chevron icon styling to match the look */
}

.job-info{
  font-weight: 590;
  font-size: 0.875rem;
  color: #6c757d;
}

/* Target the Accordion Header Button specifically */
.accordion-button {
  background-color: rgba(255, 249, 241, 0.4) !important; /* Replace with your desired color */
  border: none !important; /* Remove any default border */
  box-shadow: none !important; /* Remove any default shadow */
  color: #000; /* Ensure text is visible against the background */
  padding: 12px;
}

/* When the button is collapsed, keep the same background */
.accordion-button.collapsed {
  background-color: rgba(255, 249, 241, 0.4) !important; /* Keep the background consistent */
  border: none !important;
}

.accordion-collapsed.collapsed{
  border: none !important;
}

.accordion.body{
  border: none !important;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

/* Stacked Images on the Left */
.image-stack-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stacked-images {
  width: 100%;
  margin-bottom: -4px; /* Slightly overlap the images */
  border-radius: 5px; /* Add subtle border radius */
}

/* Description and Qualification on the Right */
.description-col {
  padding-left: 2.5rem;
  position: relative; /* Needed for the "Apply" button */
}

.job-description h5 {
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 8px;
  color: #828282;
}

.job-description p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 4px;
}

.job-description ul {
  list-style-type: disc;
  padding-left: 20px;
}

.job-description ul li {
  margin-bottom: 4px;
}

/* Apply Button Styling */
.apply-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #FFF5E4;
  border: 1px solid #00414e;
  border-radius: 20px;
  padding: 10px 40px;
  color: #00414e;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect for Apply Button */
.apply-button:hover {
  background-color: #00414e;
  color: #FFF5E4;
  transform: scale(1.05); /* Slightly increase size on hover */
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2); /* Add shadow to give depth */
}

/* General Styling to Remove Borders */
.accordion,
.card,
.card-body,
.accordion-item,
.accordion-collapse,
.accordion-body {
  border: none !important;
  box-shadow: none !important; /* Remove box shadows if any */
}

.accordion-collapse {
  background-color: rgba(255, 249, 241, 0.4);
  border-bottom: 1.5px solid #cccccc !important;
}