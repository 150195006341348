/* body {
  background-color: #f8f8f8;
} */

html,
body {
  background-color: #fff;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-white {
  background-color: #fff !important;
}

.bg-light1{
  background-color: rgba(255, 249, 241, 0.4);
}

.bg-light2{
  background-color: #FFF5E4;
}

.teal {
  color: #005c6e;
  font-weight: bold;
}

.trans {
  background-color: none !important;
  background: none !important;
}

.font-sm {
  font-size: 0.85rem !important;
}

.radius-50 {
  padding: 0.7rem;
  border-radius: 50px !important;
  background-color: #fff;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

.no-border .dropdown-toggle {
  border: none !important;
  box-shadow: none !important;
  background-color: white !important;
  color: #000 !important;
}

.no-border .btn {
  border: none !important;
  box-shadow: none !important;
}

.no-border .btn:focus,
.no-border .dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none !important;
}

/*Home Page*/

/* Hero Section */

.hero-jobs-background {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100vh;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1; /* Below content but above background */
  filter: brightness(0.8) sepia(0.1) saturate(1.2) contrast(110%); /* Darkening and warming */
}

.hero-section {
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
  color: #fff; /* Ensure the text is readable on the darker background */
}

.text-container {
  max-width: 100%;
  color: #000;
}

.hero-title {
  font-size: 1.8em;
  margin-bottom: 8px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.01em;
}

.hero-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.005em;
  color: #fff;
  margin-top: 0.85rem;
  margin-bottom: 2rem;
}

.discover-btn{
  width: auto;
  height: 45%;
  margin: 0;
  outline: none;
  position: relative;
  cursor: pointer;
  background-color: var(--background-color);
  --background-color: #FFF5E4;
  --accent-color: #00414e;
  color: #00414e;
  overflow: hidden;
  z-index: 1;
  /* border: 1px solid #FFF5E4; */
  border: none;
  transition: color 300ms ease-in-out;
  padding: 0.7rem 2.3rem;
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 1.15rem;
}

.discover-btn::before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  z-index: -1;
  background-color: var(--accent-color);
  transition: transform 400ms ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

.discover-btn:hover::before,
.discover-btn:focus::before {
  transform: scaleX(1);
}

.discover-btn:hover,
.discover-btn:focus {
  color: #FFF5E4;
}



.hero-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  
}

@media (min-width: 768px) {
  .hero-section {
    padding: 0rem auto;
  }
  .text-container {
    padding-bottom: 0rem;
  }
  .hero-title {
    font-size: 1.8em;
    text-align: left;
  }

  .hero-text {
    text-align: left;
  }

  .hero-image {
    width: 350px;
  }
}

@media (min-width: 992px) {
  .hero-title {
    font-size: 2.2em;
    text-align: left;
  }

  .hero-text {
    font-size: 1.05em;
    text-align: left;
  }

  .hero-image {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .hero-section {
    padding: 0rem auto;
  }
  .text-container {
    padding-top: 4%;
    padding-left: 4%;
  }

  .hero-title {
    font-size: 5rem;
    text-align: left;
    line-height: 1;
  }
  .hero-text {
    font-size: 1.2rem;
    text-align: left;

  }

  .hero-image {
    width: 95%;
  }
}

/* Section */
.section-wrapper {
  padding: 3rem 0;
  position: relative;
  height: 100vh; /* Set the section height to 100% of the viewport height */
  display: flex;
  align-items: center; /* Vertically center the content */
}

/* Section 1 */
.section-1-wrapper {
  padding: 3rem 0;
  position: relative;
  height: 80vh; /* Set the section height to 100% of the viewport height */
  display: flex;
  align-items: center; /* Vertically center the content */
}

.sec-1-text {
  padding-left: 2rem;
}

.sec-1-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 2rem 0rem;
}

.sec-1-description {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.6;
  text-align: justify;
}

.sec-1-img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  filter: brightness(1.05) sepia(0.3) saturate(1.6) contrast(90%); /* Darkening and warming */
}

.rounded-image {
  border-radius: 50px;
}

/* Decoration */
.sec-1-decoration {
  position: absolute;
  top: 60px;
  right: 0;
  max-width: 100px;
}

@media (min-width: 768px) {
  .sec-1-text {
    padding-left: 3rem;
  }

  .sec-1-title {
    font-size: 3rem;
  }

  .sec-1-description {
    font-size: 1.2rem;
  }
}

@media (min-width: 1200px) {
  .sec-1-text {
    padding: 0 5rem;
  }
  .sec-1-decoration {
    max-width: 150px;
  }
  .sec-1-description{
    font-size: 1.4rem;
    line-height: 1.55;
    font-weight: 400;
    padding-bottom: 1.8rem;
    color: #333;
    text-align: justify;
  }
}

/*SECTION 2 */
.section-wrapper-bg {
  height: 100vh; /* Set the section height to 100% of the viewport height */
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

/* Title */
.sec-2-title {
  font-size: 2.5rem;
  font-weight: 700;
  padding-bottom: 5rem;
}

/* Description */
.sec-2-description {
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: 400;
  padding-bottom: 1.8rem;
  color: #333;
  text-align: justify;
}

/* Highlighted Text */
.sec-2-highlight {
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: 400;
  color: #333;
  text-align: justify;
  width: 100%;
  display: block;
}

.sec-2-decoration {
  position: absolute;
  top: 0px;
  right: -20px;
  width: 350px;
}

@media (min-width: 768px) {
  .sec-2-title {
    font-size: 3rem;
  }

  .sec-2-description-,
  .sec-2-highlight {
    font-size: 1.2rem;
  }
}

@media (min-width: 1200px) {
  .sec-2-title {
    font-size: 3.5rem;
  }
  .sec-2-description,
  .sec-2-highlight {
    font-size: 1.4rem;
  }
}


/*========SECTION 3============= */

.sec-3-wrapper {
  height: 100vh; /* Full height */
  overflow: hidden; /* Prevents scrolling issues */
}

.sec-3-img {
  position: relative;
  height: 100%;
}

.image-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sec-3-content {
  height: 100%;
  background-color: #ffffff;
  padding: 1rem;
}

.sec-3-text{
  max-width: 65%; /* Prevents text from spanning too wide */
}

.sec-3-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2.2rem;
}

.sec-3-description {
  font-size: 1.4rem;
  line-height: 1.8;
  text-align: justify;
}

/*=======SECTION 4========== */

/* Carousel Container */
.carousel-container {
  padding: 5rem 6.5rem;
  background-color: #FFF5E4; /* Light background for a clean look */
}

/* Image Container Styles */
.carousel-image-container {
  padding: 0.25rem; /* Reduced padding to bring the images closer together */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 285px;
}

/* Image Styles */
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  border-radius: 20px;
}

/* Swiper Slide Styles */
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  overflow: hidden;
}

/* Progress Tracker Container */
.tracker-container {
  position: relative;
  height: 5px; /* Height of the progress bar */
  margin-top: 0px;
}

/* Light Grey Progress Tracker Background */
.light-grey-tracker {
  width: 100%;
  height: 5px;
  background-color: #d2d2d2; /* Light grey background for the tracker */
  position: absolute;
  top: 0;
}

/* Dark Grey Progress Tracker Overlay */
.dark-grey-tracker {
  height: 5px;
  background-color: #00414e; /* Dark grey or teal color for the progress bar */
  position: absolute;
  top: 0;
  transition: width 0.3s ease; /* Smooth transition for the progress bar width */
}

/* Arrow Controls */
.carousel-arrow {
  font-size: 1.5rem;
  cursor: pointer;
  color: #c0c0c0; /* Color for the arrows */
  /* background: rgba(255, 255, 255, 0.7); 
  border-radius: 50%;
  width: 40px;
  height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  transition: background 0.3s ease, transform 0.3s ease;
}

.carousel-arrow:hover {
  color: #00414e; /* Change arrow color to white when hovered */
  transform: scale(1.1); /* Slightly enlarge the arrow on hover */
}

.carousel-arrow.me-2 {
  margin-right: 10px; /* Add spacing between the left and right arrows */
}

.arrow{
  transform: scale(1.2); /* Increases the icon size */
  margin: -2px; /* Adjust negative margin to compensate for scaling */
}

/** ========SECTION 6 ======**/

.bring-ideas-section {
  background-color: #FFF5E4;
  height: 100vh;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  position: relative; /* Maintain position for decoration image */
}

.decor-image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 330px;
  z-index: 0;
}

.content-wrapper {
  width: 100%;
}

.sec-6-text {
  font-size: 1.125rem;
  line-height: 1.6;
  padding-left: 7.5rem;
  padding-right: 6.5rem;
}

.sec-6-title {
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 1.8rem;
}

.sec-6-description {
  font-size: 1.4rem;
  text-align: justify;
  line-height: 1.8;
}

.work-image {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  overflow: hidden;
  max-width: 780px;
}

/* ======SECTION 7=========== */
.section-7-wrapper {
  padding: 3rem 0;
  position: relative;
  height: 90vh; /* Set the section height to 80% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
}

/* Title */
.sec-7-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .sec-7-title {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  .sec-7-title {
    font-size: 3.5rem;
  }
}

.core-container{
  padding-top: 4rem;
}

/* Value Cards */
.value-card {
  text-align: left;
  padding: 1rem 2rem;
  border-left: 1px solid #e0e0e0; /* Adds a dividing line between columns */
}

.value-card:last-child {
  border-right: none; /* Remove the border for the last card */
}

.value-icon {
  width: 60px;
  height: 60px;
}

.agile-icon {
  width: 48px;
  height: 48px;
  margin-top: 10px;
  margin-bottom: 18px;
}

.value-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.capital-core{
  font-size: 2.75rem;
  color: #00414e;
  font-weight: 600;
}

.value-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}


/*Swiper*/
.swiper-wrapper-container {
  margin-top: 35px;
}

.swiper-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  transition-timing-function: linear;
}

.swiper-container {
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0px 10px 0px 10px;
}

.swiper-logo {
  position: absolute;
  top: 15px;
  width: 45px;
  height: 45px;
}

.swiper-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  background-color: #00414e;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 115px;
  /* box-shadow: inset 0 0 0 1px rgba(228, 193, 125, 1); */
}

.swiper-title {
  font-size: 1.5rem;
  margin-bottom: 5px;
  font-weight: bold;
  color: #e4c17d;
}

.swiper-text {
  margin: 0;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 61% !important;
  transform: translateY(61%);
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  background-color: #00414e !important;
  color: #e4c17d !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  border: 1px solid #fff;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 1.5rem;
  content: "‹"; /* Left arrow */
  margin-bottom: 5px;
}

.swiper-button-next::after {
  content: "›"; /* Right arrow */
}

@media (max-width: 768px) {
  .swiper-wrapper-container {
    margin-top: 5px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 25px;
    height: 25px;
    top: 55% !important;
    transform: translateY(55%);
  }

  .swiper-container {
    width: 100%;
    margin: 0px 8px 0px 8px;
  }
}

@media (max-width: 640px) {
  .swiper-container {
    width: 100%;
    margin: 0px 8px 0px 8px;
  }
}


/* Swiper Jobs */

.section {
  padding-top: 4%;
  height: 350px;
}

.swiper-jobs-container {
  position: relative;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0px 10px 0px 10px;
}

.swiper-jobs-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: start;
  padding: 0;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  /* box-shadow: inset 0 0 0 1px rgba(228, 193, 125, 1); */
}

.swiper-button-prev-jobs,
.swiper-button-next-jobs {
  position: absolute;
  top: 35% !important;
  transform: translateY(35%);
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  background-color: #00414e !important;
  color: #e4c17d !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  border: 1px solid #fff;
}

.swiper-button-prev-jobs {
  left: 0px;
}

.swiper-button-next-jobs {
  right: 0px;
}

.swiper-button-prev-jobs::after,
.swiper-button-next-jobs::after {
  font-size: 1.7rem;
  content: "‹"; /* Left arrow */
  margin-bottom: 5px;
}

.swiper-button-next-jobs::after {
  content: "›"; /* Right arrow */
}

@media (max-width: 768px) {
  .swiper-jobs-wrapper-container {
    margin-top: 5px;
  }

  .swiper-button-prev-jobs,
  .swiper-button-next-jobs {
    width: 37px !important;
    height: 37px !important;
    top: 33% !important;
    transform: translateY(33%);
    font-size: 2rem !important;
  }

  .swiper-jobs-container {
    width: 100%;
    margin: 0px 8px 0px 8px;
  }
}

@media (max-width: 640px) {
  .swipe-jobs-container {
    width: 100%;
    margin: 0px 8px 0px 8px;
  }
}

/*===========JOBS PAGE============= */

/*====HERO JOBS==== */

.banner-jobs-background-wrapper {
  position: relative;
  height: 100%;
}

.banner-jobs-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.hero-jobs-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 2;
}

.hero-jobs-text {
  margin-bottom: 4rem;
  color: #ffffff;
}


.jobs-title {
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
}

.jobs-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

/* Search Bar Component */

/* ===== Search Bar Component ===== */

.search-filter-bar-trans-jobs {
  background-color: none;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  width: 100%;
  z-index: 900;
}

.search-input-group {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.search-input-comp {
  padding-left: 2.8rem !important;
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  font-size: 0.8rem;
}

.search-icon-wrapper {
  position: absolute;
  top: 47%;
  left: 15px;
  transform: translateY(-50%);
  color: #00414e;
  font-size: 1.2rem;
  pointer-events: none;
  z-index: 12;
}

.input-group {
  width: 100%;
}

.input-group-text {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  color: #333;
  padding: 3px;
}

.inner-group .input-group-text {
  border-radius: 4px 0 0 4px;
}

.inner-group .form-control {
  border-radius: 0 4px 4px 0;
}

.select2 {
  width: 100%;
  cursor: pointer;
  padding-left: 10px !important;
  margin: 0 !important;
  font-size: 0.8rem;
}

/* Spacing Helpers */
.padding-right-0 {
  padding-right: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-12 {
  padding-left: 12px;
}

.margin-top-0 {
  margin-top: 0;
}

/* Button Styles animation */
.button-temp-jobs {
  width: auto;
  height: 45%;
  margin: 0;
  border-radius: 30px;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--background-color);
  --background-color: #FFF5E4;
  --accent-color: #00414e;
  color: #00414e;
  overflow: hidden;
  z-index: 1;
  border: 1px solid #FFF5E4;
  transition: color 300ms ease-in-out;
  padding-top: 13px !important;
}

.button-temp-jobs.clear-button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  z-index: -1;
  background-color: var(--accent-color);
  transition: transform 400ms ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

.button-temp-jobs.clear-button:hover::before,
.button-temp-jobs.clear-button:focus::before {
  transform: scaleX(1);
}

.button-temp-jobs.clear-button:hover,
.button-temp-jobs.clear-button:focus {
  color: #FFF5E4;
}

/* ===== Media Queries ===== */
@media (max-width: 575px) {
  .padding-right-0,
  .padding-right-024,
  .padding-left-0 {
    padding-right: 12px;
    padding-left: 12px;
  }

  .padding-left-12,
  .padding-right-12 {
    padding-left: 0;
  }

  .radius-50 {
    padding: 0.4rem;
  }

  .margin-top-0 {
    margin-top: 6px !important;
  }
  .search-filter-bar-trans-jobs {
    width: 95%;
  }

  .jobs-title {
    font-size: 2.5rem;
  }

  .jobs-subtitle {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .search-button,
  .clear-button {
    margin-right: 10px;
    font-size: 0.8rem;
    margin-top: 0;
  }

  .input-group-text {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    color: #333;
  }

  .select2 {
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 2px;
  }

  .form-control {
    font-size: 0.8rem;
  }

  .search-filter-bar-trans-jobs {
    width: 95%;
  }

  .jobs-title {
    font-size: 3rem;
  }

  .jobs-subtitle {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .button-temp-jobs {
    padding: 12px 0;
  }

  .search-button,
  .clear-button {
    margin-right: 10px;
    font-size: 1rem;
  }

  .select2 {
    font-size: 1rem;
  }

  .form-control {
    font-size: 1rem;
  }

  .search-filter-bar-trans-jobs {
    width: 95%;
    padding: 0 11rem !important;
  }
}


/**  JOB LIST */

.job-amount {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 700;
  padding-top: 1.5%;
}

.job-list-wrap {
  width: 75%;
  margin-top: 0px;
}

@media (max-width: 767px) {
  .job-amount {
    width: 100%;
    font-size: 1.2rem !important;
    font-weight: 700;
    padding-top: 20px !important;
    line-height: 1.2;
  }
  .job-list-wrap {
    width: 100% !important;
  }
}

@media (max-width: 1199px) {
  .job-amount {
    font-size: 1.3rem;
    padding-top: 3%;
    line-height: 1.2;
  }
  .job-list-wrap {
    width: 55%;
  }
}

/* JOB DETAIL PAGE */

.job-detail {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 1200px;
}

.header-detail-bg {
  background-color: #FFF5E4;
}

.header-detail {
  padding: 5.5rem 13rem 1.5rem 13rem;
}

.header-detail h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #00414e;
  /* color: #e4c17d; */
}

.header-detail-sm {
  padding: 5.5rem 8rem 0.5rem 8rem;
}

.header-detail-sm p {
  color: #e4c17d;
  font-size: 0.9rem;
  margin: 0;
}

.header-detail-sm h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #00414e;
}

.job-meta {
  font-size: 1rem;
  color: #00414e;
  margin-bottom: 1rem;
}

.job-description {
  margin-top: 35px;
  max-height: 72vh; /* Adjust the value to your preference */
  overflow-y: auto;
  padding-right: 1rem;
  margin-bottom: 2rem;
  -webkit-overflow-scrolling: touch;
}

.job-description::-webkit-scrollbar {
  width: 0px; /* Remove the scrollbar for WebKit browsers */
  background: transparent; /* Optional: Remove the scrollbar background */
}

.job-detail h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.job-detail ul {
  list-style-type: disc;
  margin-left: 0rem;
}

.job-detail ul li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.apply {
  border-left: 1.5px solid #a5a5a5; /* Add a gray border on the left */
  padding-left: 30px; /* Add some padding to separate the content from the border */
}

.division-wrapper {
  width: auto;
}

.division {
  font-size: 0.9rem;
  font-weight: 500;
  background-color: none;
  color: #00414e;
  padding: 4px 12px 6px 12px;
  border-radius: 20px;
}

.location {
  color: #dddddd;
  font-size: 1.1rem;
}

.job-type {
  font-size: 1.1rem;
  color: #dddddd;
}

.placement {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
}

.division-detail {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

.location-detail {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}

.icon-bold {
  text-shadow: 0.6px 0.6px 0px #000;
  font-weight: bold;
}

.job-type-detail {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

.placement-detail {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

.division-form {
  font-size: 1rem;
  font-weight: 500;
  color: #00414e;
}

.location-form {
  color: #00414e;
  font-size: 1rem;
}

.job-type-form {
  font-size: 1rem;
  color: #00414e;
}

.placement-form {
  font-size: 1rem;
  color: #00414e;
}

.job-detail-info-text {
  font-size: 0.9rem;
  color: #6a6a6a;
}

.button {
  padding: 7px 32px 7px 32px;
  border-radius: 18px;
  font-size: 1.1rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
}

.btn-apply1 {
  background-color: #FFF5E4;
  color: #00414e;
  transition: background-color 0.3s ease;
  border: 1px solid #00414e;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
}

.btn-apply {
  background-color: #00414e;
  color: #e4c17d;
  transition: all 0.3s ease;
  border: 1px solid #00414e;
}

.btn-back {
  border: 1px solid #9b743d;
  transition: background-color 0.3s ease;
  color: #9b743d;
}

.btn-apply1:hover {
  background-color: #00414e;
  /* border: 1px solid #FFF5E4; */
  color: #FFF5E4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-apply:hover {
  background-color: #e4c17d;
  border: 1px solid #00414e;
  /* box-shadow: inset 0 0 0 1px #9b743d; */
  color: #00414e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-back:hover {
  background-color: #9b743d;
  border: 1px solid #9b743d;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 576px) {
  .header-detail {
    padding: 6rem 0rem 1.8rem 1.8rem !important ;
    align-items: start !important;
  }
  .header-detail-sm {
    padding: 6rem 0rem 1.8rem 1.8rem !important ;
    align-items: start !important;
  }
}

@media (max-width: 768px) {
  .job-detail {
    width: 90%;
    margin-top: 0.3rem;
  }

  .header-detail h1 {
    font-size: 2rem !important;
  }

  .job-description {
    max-height: none; /* Remove the max-height on mobile */
    overflow-y: visible; /* Allow normal scrolling on mobile */
  }

  .job-detail h3 {
    font-size: 1.1rem;
  }

  .job-detail ul {
    margin-left: 0px;
  }

  .job-detail ul li {
    font-size: 1rem;
  }

  .apply {
    border-left: 0px;
    padding-left: 0px;
  }
  .button {
    margin-top: 20px;
    padding: 7px 24px 7px 24px;
    border-radius: 18px;
  }
  .btn-apply1 {
    font-size: 1rem !important;
    width: auto;
    padding: 7px 40px 7px 40px;
  }

  .btn-back,
  .btn-apply {
    font-size: 1rem;
  }
  .header-detail {
    padding: 5.5rem 0rem 1.5rem 1.8rem;
    align-items: start !important;
  }
}

@media (max-width: 992px) {
  .header-detail {
    padding: 5.5rem 0rem 1.5rem 0rem;
  }
  .header-detail h1 {
    font-size: 2.6rem;
  }
}

@media (max-width: 1200px) {
  .header-detail {
    padding: 5.5rem 2rem 1.5rem 1.2rem;
  }
  .btn-apply1 {
    font-size: 0.9rem;
  }
}

/* JOB FORM PAGE */

.light-teal-bg {
  background-color: #ecf1ef;
}

.apply-form {
  margin-top: 2%;
  width: 70%;
  background-color: #f9f9f9;
  padding: 30px 60px 60px 60px;
  margin-bottom: 6%;
  border-radius: 18px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.apply-form h1 {
  margin-bottom: 35px;
  color: #00414e;
  font-weight: 700;
}

.apply-form .form-group {
  margin-bottom: 20px;
}

.apply-form .form-label {
  font-weight: 600;
  color: #333;
}

.question-title {
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 700;
  color: rgba(0, 65, 78, 0.6);
  text-transform: uppercase;
}

.apply-form .form-control {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
}

.apply-form .form-check {
  margin-bottom: 10px;
}

.submit-button {
  background-color: #FFF5E4 !important;
  border: 1px solid #00414e !important;
  color: #00414e !important;
  padding: 9px 28px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 15px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  box-shadow: none !important;
}

.submit-button:hover {
  background-color: #00414e !important;
  border: 1px solid #FFF5E4 !important;
  color: #FFF5E4 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apply-form .prev-button {
  border: 1px solid #00414e;
  transition: background-color 0.3s ease;
  color: #00414e;
  padding: 9px 20px;
  border-radius: 18px;
  font-weight: 400;
  font-size: 15px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: none !important;
}

.apply-form .prev-button:hover {
  background-color: #00414e;
  border: 1px solid #00414e;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  text-align: center;
  flex: 1;
  position: relative;
  cursor: pointer; /* Add cursor pointer for clickable indication */
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: #bbb;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.label {
  margin-top: 8px;
}

.step.active .circle {
  background-color: #00414e;
  color: #fff;
}

.step::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #bbb;
  top: 20px;
  left: -50%;
  z-index: 0;
}

.step:first-child::before {
  content: none;
}

.step.active::before {
  background-color: #00414e;
}

.dot-indicator {
  display: flex;
  align-items: center;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #717171;
  height: 12px;
  width: 12px;
}

.required {
  display: inline;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0; 
  z-index: 10; 
}

.mbti-img-container {
  width: auto;
}

.mbti-img {
  width: 100%;
  /* width: 800px; */
}

.application-wrapper {
  width: 180px;
}

.application-img {
  width: 90px;
  height: auto;
}

.application-text {
  font-size: 0.85rem;
  font-weight: 300;
  text-align: center;
}

.download-container {
  width: 180px;
}

.download-wrapper {
  width: auto;
}

@media (max-width: 576px) {
  .apply-form {
    padding: 30px 25px 60px 25px;
    margin: 0px;
  }
  .remove-padding {
    padding: 0px;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 992px) {
  .apply-form {
    width: 100%;
  }
}

@media (max-width: 1200px) {
}
